// Letters
$pLetterFont: 'Roboto', sans-serif;

// Colors
$pColor: #4c94b6;
$sColor: #6aa7f8;
$tColor: #1a73e8;

//Sidebar
$pColorIc: #fff;
$cHoverIc: #1a73e8;
$colorTo: #000000;
$backTol: #d1e4fc;
$bColorB: #1a73e8;

//user
$headTable: #4c94b6;

//card
$content: #f5f5f5;

//Perfil
$btnClose: #031d50;
$emailUser: #6a6a6a;
$nameUser: #000;

$pButtonLetter: #fff;

.bg-primary {
  background-color: $pColor;
}

* {
  font-family: $pLetterFont !important;
}

html,
body {
  height: 100%;
  overflow-y: hidden;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.65);
}

.custom-button {
  color: $pButtonLetter;
  font-size: 18px;
  // background-color: $pColor;

  * {
    color: $pButtonLetter;
  }
}

.blue-button:hover {
  background-color: #1457AE;
}

.login-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-bottom: 150px;
}

.login-text {
  font-family: Helvetica, Arial, sans-serif !important;
}

.login-input > * {
  color: black;
}

.login-icon {
  color: $pColor;
}

@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* ===========================        INPUT PASSWORD      =======================================*/
.input-password-content input::-ms-reveal,input::-ms-clear {
  display: none;
}
/* ===========================        BUSCAR GLOBAL      =======================================*/

.global-search{
  width: 550px;
  color: #000;
}
.contenedorp{
  border: solid 1px red;
}

/* ===========================        AVATAR             =============================================*/
.avatar img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

/* ===========================        TOOLTIP              =============================================*/

/* Tooltip container */
.tooltip {
  position: relative;
  font-weight: bold;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  position: absolute;
  text-align: center;
  border-radius: 15px;
  z-index: 1;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  top: 100%;
  left: 50%;
  width: 11vh;
  background-color: whitesmoke;
  color: black;
  margin-left: -45px;
}

/* ===========================        MODAL              =============================================*/
.modal {
  width: 200px;
  position: absolute;
  right: 0;
}

.modal-header {
  background-color: #fff;
  color: black !important;
  padding: 5px;
}

.modal-body {
  // background-color: $pColor;
  display: flex;
  flex-direction: column;
  ::placeholder {
    font-size: 18px;
    font-style: italic;
    color: black;
    opacity: 1;
  }

  .button {
    background-color: #35526f;
    color: #f1f2f3;
    display: inline-block;
    padding: 3px;
    width: 60%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0 !important;
    cursor: pointer;
  }

  p {
    display: inline-block;
    margin: 0;
  }
}

.modal-footer {
  margin-top: 20px;
  justify-content: space-around;
}


/* ============================ Modal Storage  ======================================== */
.container{
  text-align: center;
  justify-content: center;
}
.btnCancel{
  margin-right: 10px;
}

/* ============================ sidebar  ========================================*/
#header {
  position: absolute;
  width: 100px;
}
#header .pro-sidebar {
  height: 100vh;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar-inner {
  background-color: $pColorIc;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  background-color: $sColor;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 35px;
  margin: 0 auto;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding-bottom: 50px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 0px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: $pColorIc;
  margin: 0px 0px;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: $pColorIc;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: $pColorIc;
}

#header .logo {
  padding: 20px;
}

.docset {
  padding-top: 25px;
  width: 100%;
  margin: 0 auto;
}

.tooltip {
  text-align: center;
  font-weight: bold !important;
  font-size: 12px !important;
  color: $colorTo !important;
  background: $backTol !important;
}
.hover:hover {
  background-color: $cHoverIc !important;
}
.ico {
  font-size: 17px;
}
.back,
.react-slidedown {
  background: $sColor !important;
}
.pro-arrow {
  border-color: $pColorIc !important;
}
.pro-inner-item::before {
  border-color: $pColorIc !important;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

.tab_active {
  background-color: $cHoverIc;
}
//User
.add {
  display: flex;
  columns: 2;
  margin-bottom: 20px;
}

.btnAdd {
  background-color: $cHoverIc;
  margin-left: 50px;
  height: 35px !important;
  min-width: 35px !important;
}

.btn_section {
  margin-left: 10px;
  min-width: 35px;
}

.btn_item {
  float: right;
  margin: 5px;
  min-width: 35px;
}

.icoAdd {
  color: #fff;
  font-weight: bolder;
}

.icoClos {
  color: $colorTo;
  font-weight: bolder;
}

.btnAdd:hover {
  background-color: $sColor;
  color: #fff;
}

.headTable {
  color: $headTable;
  font-weight: bolder;
  text-align: center;
}

.cellTable {
  color: $colorTo;
  text-align: center;
}

.search_wrap {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0px 50px 10px 50px;
}

.designdiv2 {
  padding: 0px 50px;
}
.design_body_2 {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.stateTemplate{
  display: none;
}

.search_wrap_user {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0px 50px 10px 0px;
}

//config
.btnConfigFolder {
  background-color: #08cf58; //#09aa09 
  margin-left: 50px;
  height: 35px !important;
  min-width: 35px !important;
}

/* ============================ folder  ========================================*/
.content_folder {
  justify-content: space-between;
}
.iconButton:hover{
  background: 0
}
.arrow {
  background-color: rgb(207, 235, 243);
  margin-top: 6px;
}
.name {
  padding-left: 15px;
}
.folder_container {
  margin-bottom: 10px;
}

.hijo2 {
  align-items: center;
  text-align: center;
  justify-content: center;
}

.folder_icon{
  margin-left: 10px;
}

.start_icon {
  color: $pColor;
  position: absolute;
  padding: 2px;
}

/* ============================ card  ========================================*/
.design_body {
  padding: 20px;
  height: 100hv;
}
.grid_card {
  padding: 10px 10px 10px 0px;
}

.option {
  padding: 10px 5px 0px 0px;
  display: flex;
  float: right;
  color: black;
}
.optionsMenuDropdown {
    margin-left: 7.5%;
}
   @media (max-width: 360px) {
    .optionsMenuDropdown {
        margin-left: 4%;
    }
  }
  @media (max-width: 1336px) {
    .optionsMenuDropdown {
        margin-left: 2%;
    }
  }
  @media (max-width: 1920px) {
    .optionsMenuDropdown {
        margin-left: 6%;
    }
  }
  @media (max-width: 1440px) {
    .optionsMenuDropdown {
        margin-left: 3%;
    }
  }
  @media (max-width: 720px) {
    .optionsMenuDropdown {
        margin-left: 2%;
    }
  }
  @media (max-width: 768px) {
    .optionsMenuDropdown {
        margin-left: 4%;
    }
  }
  @media (max-width: 1024px) {
    .optionsMenuDropdown {
        margin-left: 4%;
    }
  }
  @media (max-width: 1190px) {
    .optionsMenuDropdown {
        margin-left: 7% !important;
    }
  }

.media {
  justify-content: Center;
  margin: 20px auto;
  height: 50px;
  width: 50px;
}

.content {
  background-color: $content;
  padding-top: 20px;
}

.fontTime {
  font-size: 10px;
  color: gray;
  padding-top: 10px;
}

.page_title {
  font-size: 28px;
  margin-bottom: 5px;
}

.page_note {
  font-size: 18px;
  color: $pColor;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
}

.icon {
  margin-right: 10px;
  color: gray;
}

/* ============================ cabecera de tabla  ========================================*/
.headTable{
  font-size: 14px;
  background: $pColor;
  color: white;
  font-weight: bold;
  border: 1px solid $pColor;
}
.cellTable{
  font-size: 13px;
  // border: 1px solid $pColor;
  cursor: default;
}

/* ============================ perfil  ========================================*/
.nameUser {
  color: $nameUser;
  margin-bottom: 20px;
  margin-top: 20px;
}
.emailUser {
  color: $emailUser;
  margin-bottom: 10px;
}
.modalPerfil {
  padding: 25px 25px 0 25px;
  text-align: center;
  margin-right: 5%;
  margin-left: 5%;
  border: none;
  max-height: 430px;
  margin-top: 0.1%;
  display: flex;
  justify-content: flex-end;
}
.modalPerfil:focus {
  outline: none;
  box-shadow: none;
}
.avatar {
  margin: 0 auto;
  background: #fff;
  padding: 1px;
  width: 120px;
  height: 120px;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.icoCamara {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ececeb;
  border-radius: 50%;
  border: 2px solid #ececeb;
  color: $btnClose;
}
.btnClose {
  background-color: $btnClose;
  color: #fff;
  padding: 5px 30px;
  border-radius: 10px;
  font-size: 15px;
}

.btnClose:hover {
  background-color: $pColor;
}

.positionElements {
  display: flex;
  columns: 2;
  justify-content: space-between;
  margin-left: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.ml2 {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.footerRight {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
}
.input {
  border: 2px solid rgb(75, 75, 75);
  padding: 7px;
  width: 100%;
  border-radius: 7px;
  font-size: 17px;
}
.rd {
  border-radius: 30px !important;
}
//user modal
.addOrder {
  display: flex;
  columns: 2;
  margin-bottom: 15px;
  justify-content: space-between;
  margin-right: 15px;
}
.orderTypography {
  width: 40%;
}

.inputUser {
  width: 60%;
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid $headTable;
}
.rightInput {
  width: 60%;
  justify-content: space-between;
}
.selectWidht {
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid $headTable;
  width: 100%;
}
.is-invalid {
  border: red solid 1px;
}
.exito {
  text-align: center;
  color: #0085ff;
}
.error {
  color: red;
}
.formControlBox {
  width: 100%;
  height: 37px;
  border: 1px solid #cccccc;
  font-size: 15px;
  border: radius 5px;
  padding: 5px;
}

.custom-datepicker {
  width: 100%;
  margin-top: 0px;

  * {
    color: black;
  }
}

.custom-divider {
  margin: 30px 0px 10px 0px;
}

.custom-input {
  line-height: 2.5 !important;
}

.all-heigth {
  height: 100vh;
}

@media (max-width: 959px) {
  .custom-login {
    padding: 0px 50px;
  }

  .md-show {
    display: none;
  }
}

.notfound-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: $pColor;
}

.notfound-page img {
  height: 320px;
  width: 320px;
}

.notfound-page p {
  color: #fff;
  font-size: xxx-large;
}

.item-center {
  display: flex;
  align-items: center;
}

.link {
  font-size: 18px;
  color: $pColor;
  text-decoration: none;
  font-weight: bold;
}

/* ====================================== scroll  ==================================================*/
.scrolly{
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 95%;
}

.basePage{
  overflow-y: hidden;
  height: 100vh;
}